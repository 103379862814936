<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :headers="headers"
          :items="writers"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :search="search"
          :is-loading="isLoadingData"
          title="Penulis"
          subtitle="Halaman untuk menambah penulis"
          add-btn="Tambah Data"
          @change-page="paginationHandler"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      persistent
      :width="600"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Penulis': 'Edit Penulis'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
        class="pa-4"
      >
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="writer.name"
                label="Nama Penulis"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :width="600"
      :is-loading-button="isLoadingButton"
      header="Hapus Penulis"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    ConfirmDialog,
    ModalDialog,
    MainCard,
  },
  data() {
    return {
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Writer', value: 'name' },
        { text: 'Actions', value: 'actions' },
      ],
      page: 1,
      totalPages: 0,
      totalItems: 0,
      dialog: '',
      writers: [],
      writer: {
        uuid: '',
        name: '',
      },
      search: '',
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      filterQuery: {
        writer: '',
      },
      formValid: false,
    }
  },
  watch: {
    writer: {
      handler() {
        const valid = []
        const requiredField = ['name']
        Object.entries(this.writer).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listWriter(this.filterQuery)
      },
    },
  },
  async mounted() {
    await this.listWriter()
  },
  methods: {
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },
    resetForm() {
      this.writer.name = ''
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async listWriter(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('writer', { ...params, page: this.page }).then(
        ({ data }) => {
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
          this.writers = data.data.map((writers, index) => ({
            ...writers,
            index: index + 1,
          }))
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add('writer', this.writer).then(
        ({ data }) => {
          this.listWriter(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.resetForm()
      this.modalDialog = false
      this.isLoadingButton = false
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get('writer', uuid).then(
        ({ data }) => {
          this.writer = data.data
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },
    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update('writer', this.writer, this.writer.uuid).then(
        ({ data }) => {
          this.listWriter(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      this.resetForm()
      this.modalDialog = false
      this.isLoadingButton = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.writer.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('writer', this.writer.uuid).then(
        ({ data }) => {
          this.listWriter(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
        },
        err => console.error(err),
      )
      this.confirmDialog = false
      this.isLoadingButton = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    searchHandler(data) {
      this.filterQuery.writer = data
      this.page = 1
      this.listWriter(this.filterQuery)
    },
  },
}
</script>

<style>
</style>
