<template>
  <MainCard id="library-card">
    <template v-slot:body>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="(tabItem,index) in tabs"
          :key="tabItem.icon"
          @click="reRenderComponent(index)"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tabItem.icon }}
          </v-icon>
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <library-table :key="componentKey"></library-table>
        </v-tab-item>
        <v-tab-item>
          <library-table-free :key="componentKey1"></library-table-free>
        </v-tab-item>
        <v-tab-item>
          <library-setting :key="componentKey2"></library-setting>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </MainCard>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import LibrarySetting from './LibrarySetting.vue'
import LibraryTable from './LibraryTable.vue'
import LibraryTableFree from './LibraryTableFree.vue'

export default {
  name: 'Tabs',
  components: {
    LibraryTable,
    LibrarySetting,
    LibraryTableFree,
    MainCard,
  },
  props: {},
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Buku Berbayar' }, { title: 'Buku Gratis' }, { title: 'Setting' }],
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
    }
  },
  methods: {
    reRenderComponent(index) {
      if (index === 0) {
        this.componentKey += 1
      } else if (index === 1) {
        this.componentKey1 += 1
      } else {
        this.componentKe2 += 1
      }
    },
  },
}
</script>

<style>
</style>
